import { Link, useParams } from 'react-router-dom'
import './index.scss'
import { useEffect, useState } from 'react';
import { obterApi } from '../../../../api';

export default function UserChegada() {
    const [qrcode, setQrcode] = useState('');
    const [info, setInfo] = useState({});
    const { inscricao } = useParams();

    useEffect(() => {
        async function buscarInscricao() {
            try {
                let resp = await obterApi().get('/inscricaoConfirmacao/select/' + inscricao);
                setInfo(resp.data);
                setQrcode(resp.data.qrcode);
            }
            catch (err) {
                alert(err.response.data.erro)
            }
        }
        buscarInscricao();
    }, []);


    async function concluir() {
        try {
            let resp = await obterApi().post('/inscricaoConfirmacao', { id: info.inscricao, qrcode: qrcode });
            alert('Inscrição confirmada');
        }
        catch (err) {
            alert(err.response.data.erro)
        }
    }


    return (
        <div className='pagina-chegada'>

            <header>
                <div className='logo'>
                    <img src="/assets/images/logofrei_fundo.svg" alt="" />
                </div>
            </header>

            <main>
                <section className='informacoes'>
                    <h1>Informações do usuário:</h1>
                    <hr />
                </section>

                <section className='bloco'>


                    <div className='user'>
                        <h2>Usuário</h2>

                        <div className='info'>
                            <input type="text" placeholder='Nome' value={info.visitante} />
                            <img src="/assets/images/user.png" alt="" />
                        </div>

                    </div>

                    <input type="tel" placeholder='Telefone: 00 00000-0000' value={info.telefone} />
                    <input type="text" placeholder='CEP: 00000-000' value={info.cep} />
                    <input type="text" placeholder='Bairro:' value={info.bairro} />
                    <input type="date" placeholder='Data de nascimento: 00/00/0000' value={info.nascimento?.split('T')[0] ?? ''} />

                    <div className='qrcode'>
                        <h2>Insira o código do QRcode</h2>

                        <div className='codigo'>
                            <input type="text" value={qrcode} onChange={e => setQrcode(e.target.value)} />
                            <div style={{display: 'flex', alignItems: 'center', gap: '20px'}}>
                                <Link to='/chegada'>Voltar</Link>
                                <div className='botao' onClick={concluir}>CONCLUIR</div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </div>
    )
}