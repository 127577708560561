import { useEffect, useState } from 'react'
import './index.scss'

import { Link, useNavigate } from 'react-router-dom'
import { usuarioEstaLogado } from '../../../../auth'
import { withMask } from 'use-mask-input';
import { ENDPOINT, obterApi } from '../../../../api';


export default function Chegada() {
    const [filtro, setFiltro] = useState('');
    const [dados, setDados] = useState([]);

    function buscarUsuarios() {
        obterApi().get(`/inscricaoConfirmacao/filtro?numero=${filtro}`)
            .then(resposta => resposta.data)
            .then(setDados)
            .catch(console.log)
    }

    const navigate = useNavigate();

    useEffect(() => {
        if (!usuarioEstaLogado()) {
            navigate('/login')
        }
    }, [])

    return (
        <div className='pagina-1chegada'>
            <div className='fundo'>
                <img src="assets/images/fundo.svg" alt="" />
            </div>

            <header>
                <div className='logo'>
                    <img src="assets/images/logofrei_fundo.svg" alt="" />
                </div>
                <div className='texto'>
                    <h1>ADMINISTRADOR</h1>
                    <h1>|</h1>
                    <h1>Instituto Social N.S. de Fátima</h1>
                </div>
            </header>

            <main>
                <section className='informacoes'>
                    <h1>Marcação de Chegada</h1>
                    <hr />
                </section>

                <section className='bloco'>
                    <div className="conteudo">
                        <div className="celular">
                            <p className='input-title'>Informe o telefone com DDD:</p>
                            <div className="input">

                                <input
                                    onChange={(e) => setFiltro(e.target.value)}
                                    placeholder="00 00000-0000"
                                    ref={withMask("(99) 99999-9999")}
                                    type="text"
                                    id="telefone"
                                    value={filtro}
                                />
                                <img
                                    className='iconpesq'
                                    src="/assets/images/pesquisar.svg"
                                    alt="Pesquisa"
                                    onClick={buscarUsuarios}
                                />
                            </div>
                        </div>

                        <table className='responsive-table'>
                            <tr className='table-header'>
                                <td className='col col-1'>Nome</td>
                                <td className='col col-2'>Bairro</td>
                                <td className='col col-3'>Situação</td>
                            </tr>
                            
                            {dados.length > 0
                                ? dados.map(d =>
                                    <tr 
                                        key={d.nome+d.situacao}
                                        onClick={() => navigate(`/confirmacao/${d.id}`)}
                                        className="table-row">
                                        <td className='col col-1' data-label='Nome'>{d.nome}</td>
                                        <td className='col col-2' data-label='Bairro'>{d.bairro ?? 'Não encontrado'}</td>
                                        <td className='col col-3' data-label='Situacao'>{d.situacao}</td>
                                    </tr>
                                )
                                :
                                <tr>
                                    <td colSpan={3}>
                                        Nenhum registro encontrado
                                    </td>
                                </tr>
                            }
                        </table>
                    </div>

                </section>
            </main>
        </div>
    )
}
