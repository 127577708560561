import axios from "axios";

export const TOKEN = 'TOKEN';
//export const ENDPOINT = 'http://localhost:3010'
export const ENDPOINT = 'https://feiradeprofissoes-insf.com.br/api'


export const obterApi = () => axios.create({
    baseURL: ENDPOINT,
    headers: {
        "x-access-token": localStorage.getItem(TOKEN)
    }
});
