import { useState } from 'react';
import './index.scss';
import { entrar } from '../../auth';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';

export default function Login() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [erro, setErro] = useState('');
    const navigate = useNavigate();
    
    async function entrarClick(e) {
        e.preventDefault();
        
        try {
            await entrar(username, password)
            navigate('/chegada')
        } catch (error) {
            setErro(error.toString())
        } 
    }

    return (
        <div class="login-container">
            <h2 className='title-adm'>ADMINISTRADOR</h2>
            <Link className='button_voltar'><button >VOLTAR</button></Link>
            <div className="container">
                    <div className="visual">
                        <h2>LOGIN ADM</h2>
                        <div className="linha"></div>
                        <img src="/assets/images/logo.png" alt="Logo" className="logo"  />
                    </div>
                <div className="barInput">
                    <p>{erro}</p>
                    <div className="input-group1">
                        <input 
                            className='input-information1'
                            type="text" 
                            placeholder="Nome" 
                            onChange={(e) => setUsername(e.target.value)}
                            required 
                        />
                    </div>
                    <div className="input-group1">
                        <input 
                        className='input-information1'
                        type="password" 
                        placeholder="Senha"
                        onChange={(e) => setPassword(e.target.value)}
                        required 
                        />
                    </div>
                    <button 
                    type="submit" 
                    onClick={entrarClick} 
                    className="btn"
                    >ENTRAR</button>
                </div>
            </div>
        </div>
    );
}